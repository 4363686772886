<template>
	<lassoLayout class="page-profile-basics">
		<div class="page_form_layout">
			<div class="wrap_form_layout">
				<h2 class="profile_basics">Profile Basics</h2>
				<div class="form">
					<div class="full_name">
						<label for="">Full Name <span>(creator profiles are separate)</span></label>
						<input autofocus type="text" v-model="fullName" autocomplete="off">
						<div class="hint_message_error">
							<span v-if="!$v.fullName.isNameValid">Only alphanumeric characters, hyphen and space</span>
							<span v-if="!$v.fullName.maxLength">30 characters max</span>
						</div>
					</div>
					<div class="location">
						<label for="">Location</label>
						<vue-google-autocomplete
							id="location"
							classname="form-control"
							placeholder="City, State"
							types="(cities)"
							v-on:keyup="keypressLocation"
							v-on:placechanged="getAddressData">
						</vue-google-autocomplete>
						<div class="hint_message_error">
							<span v-if="!$v.location.isLocationValid">Only alphanumeric characters, hyphen and space</span>
							<span v-if="!$v.location.maxLength">200 characters max</span>
						</div>
					</div>
				</div>
				<button v-if="!$v.fullName.$invalid" type="button" class="save_profile_basics primary_btn" v-ripple @click="updateProfile"
				        :class="{'loading': loader}">
					Continue
					<v-progress-circular :width="2" indeterminate></v-progress-circular>
				</button>
				<button v-else type="button" class="save_profile_basics primary_btn" disabled>Continue</button>

			</div>
		</div>
	</lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {maxLength, alphaNum} from 'vuelidate/lib/validators';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {helpers} from 'vuelidate/lib/validators';
import lassoLayout from "@/layouts/lassoLayout.vue";

export default {
	name: 'profile-basics',
	components: {
		lassoLayout,
		VueGoogleAutocomplete
	},
	data() {
		return {
			loader: false,
			fullName: '',
			location: '',
		}
	},
	watch: {
		'$route'(to, from) {
			this.getProfileData();
		}
	},

	// Validations
	validations: {
		fullName: {
			isNameValid: helpers.regex('isNameValid', /^[A-Za-z0-9-.,\sа-яА-Яєіїґё]*$/i),
			maxLength: maxLength(30)
		},
		location: {
			isLocationValid: helpers.regex('isLocationValid', /^[A-Za-z0-9-.,\sа-яА-Яєіїґё]*$/i),
			maxLength: maxLength(200)
		}
	},
	computed: {
		...mapGetters(['userID', 'AUTH_DATA', 'USER', 'PROFILE']),
	},
	mounted() {
		this.getProfileData();
	},
	methods: {
		...mapActions(['CHANGE_USER_DATA', 'GET_PROFILE']),
		keypressLocation(event) {
			this.location = event.target.value;
		},
		getAddressData: function (addressData, placeResultData) {
			if (addressData.country === 'United States') {
				this.location = `${addressData.locality},  ${addressData.administrative_area_level_1}, ${addressData.country}`;
			} else {
				this.location = `${addressData.locality}, ${addressData.country}`;
			}
		},

		updateProfile() {
			this.loader = true;

			if (!this.location) {
				this.location = 'Planet Earth';
			}
			if (!this.fullName) {
				this.fullName = this.AUTH_DATA.userName.split('@')[0];
			}
			const userData = {
				id: this.userID,
				address: this.location.trim(), // TODO: rename address to location ~backend~
				fullName: this.fullName.trim()
			}
			this.CHANGE_USER_DATA(userData)
				.then(() => {
					this.$router.push({name: 'memberships'});
					// this.$router.push({name: 'private-profile'});
				})
				.catch(err => {
					console.error(`updateProfile, CHANGE_USER_DATA ${err}`);
				})
				.finally(() => {
					this.loader = false;
					this.getProfileData();
				});
		},
		getProfileData() {
			this.GET_PROFILE()
				.catch(err => {
					console.error(`profile-basics, GET_PROFILE ${err}`);
				});
		}
	}
}
</script>

<style lang="scss">
.profile_basics {
  margin-top: 20px;
}

.location {
  input::placeholder {
    color: #7481A6;
  }
}
</style>